import * as React from "react";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import { Link } from "gatsby";

import fineArtPreview from "../assets/cinema/fine_art_preview.mp4";
import viscomPreview from "../assets/cinema/viscom_preview.mp4";
import BigBuildPreview from "../assets/cinema/BBclip.mp4";
import fashionPreview from "../assets/cinema/fashion_preview.mp4";

const Cinema = () => {
  function playVid(e) {
    e.target.play();
  }

  function pauseVid(e) {
    e.target.pause();
  }

  return (
    <Layout>
      <Seo title={`Kingston School of Art Foundation 2021 • Cinema`} />
      <div className="cinemaMain">
        <Link className="cinemaMainItem" to="/cinema/bigbuild">
          <div className="cinemaMainItemText">
            <h2>Screen 1</h2>
            <p>Big build film</p>
          </div>
          <video
            onMouseOver={(e) => playVid(e)}
            onMouseOut={(e) => pauseVid(e)}
            className="thevideo"
            loop
          >
            <source src={BigBuildPreview} type="video/mp4" />
            <source src={BigBuildPreview} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Link>
        <Link className="cinemaMainItem" to="/cinema/fashion">
          <div className="cinemaMainItemText">
            <h2>Screen 2</h2>
            <p>Fashion film</p>
          </div>
          <video
            onMouseOver={(e) => playVid(e)}
            onMouseOut={(e) => pauseVid(e)}
            className="thevideo"
            loop
          >
            <source src={fashionPreview} type="video/mp4" />
            <source src={fashionPreview} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Link>
        <Link className="cinemaMainItem" to="/cinema/viscom">
          <div className="cinemaMainItemText">
            <h2>Screen 3</h2>
            <p>Vis comm showreel</p>
          </div>
          <video
            onMouseOver={(e) => playVid(e)}
            onMouseOut={(e) => pauseVid(e)}
            className="thevideo"
            loop
          >
            <source src={viscomPreview} type="video/mp4" />
            <source src={viscomPreview} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Link>
        <Link className="cinemaMainItem" to="/cinema/fineart">
          <div className="cinemaMainItemText">
            <h2>Screen 4</h2>
            <p>Fine art showreel</p>
          </div>
          <video
            onMouseOver={(e) => playVid(e)}
            onMouseOut={(e) => pauseVid(e)}
            className="thevideo"
            loop
          >
            <source src={fineArtPreview} type="video/mp4" />
            <source src={fineArtPreview} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Link>
      </div>
    </Layout>
  );
};

export default Cinema;
